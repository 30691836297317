import {
    determineGetFiltersData,
    determineGetScreenerData,
    determineGetScreenerDataSSR,
    determineOperateEntity,
    determineOperateMultipleEntities,
} from '@cfra-nextgen-frontend/shared/src/components/Screener/api/screener';
import { ApiNames } from '@cfra-nextgen-frontend/shared/src/utils/enums';
import { UseData, UseMultipleData, getData } from 'utils/api';

const apiName = ApiNames.PlatformManagementInternal;
export const getScreenerData = determineGetScreenerData(UseData, apiName);
export const getScreenerDataSSR = determineGetScreenerDataSSR(getData, apiName);
export const getFiltersData = determineGetFiltersData({ UseData, apiName, filtersPath: 'internal/screener-filters' });
export const operateEntity = determineOperateEntity({ UseData, UseMultipleData, apiName });
export const operateMultipleEntities = determineOperateMultipleEntities(UseMultipleData, apiName);
export const sendAlert = determineOperateEntity({
    UseData,
    apiName,
    entityPath: 'alert',
    queryKeyFirstElement: 'alert',
});
