import {
    applyCustomFilterValueFormatter,
    getFieldMetaBySourceField,
    getValueByFieldKey,
    updateMetadataUrlLinkParams,
} from '@cfra-nextgen-frontend/shared/src/components/Form/shared/utils';
import {
    Components,
    FilterMetadata,
    FiltersData,
    FiltersMetadata,
} from '@cfra-nextgen-frontend/shared/src/components/Form/types/filters';
import { ModalProps } from '@cfra-nextgen-frontend/shared/src/components/Screener/Modal';
import { CreateModalProps } from '@cfra-nextgen-frontend/shared/src/components/Screener/components/CreateModal';
import { InformationPopup } from '@cfra-nextgen-frontend/shared/src/components/Screener/components/InformationPopup';
import { ScreenerHomeProps } from '@cfra-nextgen-frontend/shared/src/components/Screener/components/ScreenerHome';
import { SendAlertWrapper } from '@cfra-nextgen-frontend/shared/src/components/Screener/components/SendAlertWrapper';
import { RhFormData } from '@cfra-nextgen-frontend/shared/src/components/Screener/filtersModal/utils';
import { combineIntoFormElementName } from '@cfra-nextgen-frontend/shared/src/components/Screener/screenerFormElements/shared';
import { ScreenerData } from '@cfra-nextgen-frontend/shared/src/components/Screener/types/screener';
import {
    OperationTypes,
    RequestTypes,
    UserPlatformManagementEntityTypes,
} from '@cfra-nextgen-frontend/shared/src/utils';
import { SearchByParams } from '@cfra-nextgen-frontend/shared/src/utils/api';
import _ from 'lodash';
import React from 'react';
import { processAuditTrailRecords } from './AuditTrailUtils';

function _getFiltersMetadataByViewType(
    screenerData: ScreenerData,
    originalFiltersMetadata: FiltersMetadata,
    isStatic: boolean,
) {
    const resultFiltersMetadata = _.cloneDeep(originalFiltersMetadata);
    const defaultValues: Record<string, any> = {};

    if (isStatic) {
        Object.keys(resultFiltersMetadata).forEach((key) => {
            resultFiltersMetadata[key].component = Components.StaticText;
        });
    }

    for (const key in resultFiltersMetadata) {
        const { item_metadata, source_field } = resultFiltersMetadata[key];

        //update url_link params
        if (item_metadata && item_metadata?.['url_link']?.['params_source_field']) {
            resultFiltersMetadata[key]['item_metadata'] = updateMetadataUrlLinkParams(item_metadata, {
                rowData: screenerData.results.data[0],
                viewdata: screenerData._viewdata,
                metadata: screenerData._metadata,
            });
        }

        //fetch or derive default value
        if (item_metadata && item_metadata?.source_fields && item_metadata?.value_formatter) {
            resultFiltersMetadata[key]['default_value'] = applyCustomFilterValueFormatter({
                rowData: screenerData.results.data[0],
                viewdata: screenerData._viewdata,
                metadata: screenerData._metadata,
                fieldMetadata: resultFiltersMetadata[key].item_metadata,
                returnRawCalculatedValue: isStatic,
            });
        } else if (typeof source_field === 'string') {
            const fieldMetaList = getFieldMetaBySourceField(screenerData._metadata, source_field);

            if (fieldMetaList.length === 0) {
                throw new Error(`Field meta not found for source_field: ${source_field}`);
            }

            const fieldPath = Object.keys(fieldMetaList[0])[0];
            const defaultValue = getValueByFieldKey({
                rowData: screenerData.results.data[0],
                viewdata: screenerData._viewdata,
                fieldPath,
            });
            resultFiltersMetadata[key]['default_value'] = defaultValue;

            let defaultValues: any[] = [];
            for (let i = 0; i < screenerData.results.data.length; i++) {
                const val = getValueByFieldKey({
                    rowData: screenerData.results.data[i],
                    viewdata: screenerData._viewdata,
                    fieldPath,
                });
                if (val !== undefined || val !== null) {
                    if (Array.isArray(val)) {
                        defaultValues = defaultValues.concat(val);
                    } else {
                        defaultValues.push(val);
                    }
                }
            }

            resultFiltersMetadata[key]['default_values'] = defaultValues.length > 0 ? defaultValues : null;
        }

        const formKey = combineIntoFormElementName({
            componentName: isStatic ? Components.StaticText : resultFiltersMetadata[key].component,
            filterMetadataKey: key,
        });

        defaultValues[formKey] = resultFiltersMetadata[key]['default_value'];
    }

    return { resultFiltersMetadata, defaultValues };
}

export function getFiltersMetadataByViewType({
    screenerData,
    filtersMetadata,
}: {
    screenerData: ScreenerData;
    filtersMetadata: FiltersMetadata;
}) {
    const { resultFiltersMetadata: nonStaticFiltersMetadata, defaultValues: nonStaticDefaultValues } =
        _getFiltersMetadataByViewType(screenerData, filtersMetadata, false);
    const { resultFiltersMetadata: staticFiltersMetadata, defaultValues: staticDefaultValues } =
        _getFiltersMetadataByViewType(screenerData, filtersMetadata, true);

    return {
        filtersMetadata: nonStaticFiltersMetadata,
        defaultValues: nonStaticDefaultValues,
        staticFiltersMetadata,
        staticDefaultValues,
    };
}

export type SingleModalProps = {
    onOperationSuccessSnackMessage: string;
    entityType: UserPlatformManagementEntityTypes;
    submitButtonName: string;
    title: string;
    buttonText?: string;
    operationType?: OperationTypes;
    requestPath?: string;
    description?: ModalProps['description'];
    onBeforeCreateSuccess?: (data: any, formData?: RhFormData, filtersData?: FiltersData) => void;
    multipleResultsPopupId?: string;
    multipleResultsPopup?: React.ReactNode;
    multipleResultsPopupTitle?: string;
    maxNumberOfItemsPerOneRequest?: number;
    presetValues?: Record<string, string>;
    localFiltersMetadata?: Record<string, Partial<FilterMetadata>>;
};

export type RowLevelFiltersConfig = {
    requestParams?: SearchByParams;
    requestDefaultValue?: Record<string, any>;
};

export type ModalWrapperProps = { ModalComponent: React.FC<CreateModalProps> } & CreateModalProps;

export type Modal = {
    props: SingleModalProps;
    ModalWrapper?: React.FC<ModalWrapperProps>;
};

export type EntityAssociatedItem = {
    screenerProps: Partial<ScreenerHomeProps>;
    modals?: Array<Modal>;
};

function BulkUploadDescription() {
    const file = 'Bulk_Upload_Template.xlsx';
    const templateLink = (
        <a href={`/files/${file}`} download={file}>
            template.xlsx
        </a>
    );

    return (
        <>
            Input list of users for bulk upload into the {templateLink}. All fields for a user must be populated in
            order for user upload.
        </>
    );
}

export function getEntityAssociatedItems(
    entityType: UserPlatformManagementEntityTypes,
    entityId: number,
): Array<EntityAssociatedItem> {
    if (entityType === UserPlatformManagementEntityTypes.Account) {
        return [
            {
                screenerProps: {
                    cardName: 'Groups',
                    screenerRequestParams: {
                        path: 'group',
                        view: 'account_profile',
                        requestBody: {
                            filters: {
                                values: {
                                    'user_management.group.account_id': {
                                        values: [entityId],
                                    },
                                },
                            },
                        },
                    },
                },
            },
            {
                screenerProps: {
                    cardName: 'Packages',
                    screenerRequestParams: {
                        path: 'group_package',
                        view: 'account_profile',
                        requestBody: {
                            filters: {
                                values: {
                                    'user_management.group.account_id': {
                                        values: [entityId],
                                    },
                                },
                            },
                        },
                    },
                },
            },
            {
                screenerProps: {
                    cardName: 'Users',
                    enableTopLeftActionPanel: true,
                    enableTopRightActionPanel: true,
                    searchPlaceholder: 'Search for User',
                    screenerRequestParams: {
                        path: 'user',
                        view: 'account_profile',
                        requestBody: {
                            filters: {
                                values: {
                                    'user_management.group.account_id': {
                                        values: [entityId],
                                    },
                                },
                            },
                        },
                    },
                    rowLevelFiltersConfig: {
                        requestParams: {
                            path: 'user',
                            type: 'bulk_edit',
                        },
                        requestDefaultValue: {
                            id: '{row_id}',
                            values: '{action_value}',
                        },
                    },
                },
                modals: [
                    {
                        props: {
                            title: 'Create User',
                            submitButtonName: 'Create User',
                            onOperationSuccessSnackMessage: 'Successfully created user {first_name} {last_name}',
                            entityType: UserPlatformManagementEntityTypes.User,
                            presetValues: {
                                [combineIntoFormElementName({
                                    componentName: Components.AutoCompleteFreeSolo,
                                    filterMetadataKey: 'user_management.user.company_name',
                                })]: '{account_name}',
                            },
                            localFiltersMetadata: {
                                'user_management.user.company_name': {
                                    disabled: true,
                                },
                            },
                        },
                        ModalWrapper: SendAlertWrapper,
                    },
                    {
                        props: {
                            title: 'Bulk User Create for {profileTitle}',
                            buttonText: 'Bulk User Create',
                            submitButtonName: 'Create',
                            onOperationSuccessSnackMessage: 'Successfully created {count} user{countBasedEnding}',
                            entityType: UserPlatformManagementEntityTypes.User,
                            operationType: OperationTypes.BULK_CREATE,
                            requestPath: 'user/_batch',
                            description: <BulkUploadDescription />,
                            multipleResultsPopupId: 'Bulk User Create Failed Results Popup',
                            multipleResultsPopup: (
                                <InformationPopup
                                    modalId={'Bulk User Create Failed Results Popup'}
                                    analyticsCardName={'Bulk User Create Failed Results Popup'}
                                />
                            ),
                            multipleResultsPopupTitle:
                                'The following {numberOfItemsFailedToOperate} users were not created:',
                            maxNumberOfItemsPerOneRequest: 20,
                            presetValues: {
                                [combineIntoFormElementName({
                                    componentName: Components.AutoCompleteFreeSolo,
                                    filterMetadataKey: 'user_management.user.company_name',
                                })]: '{account_name}',
                            },
                            localFiltersMetadata: {
                                'user_management.user.company_name': {
                                    disabled: true,
                                },
                            },
                        },
                        ModalWrapper: SendAlertWrapper,
                    },
                ],
            },
            {
                screenerProps: {
                    cardName: 'Account Audit Trail',
                    screenerRequestParams: {
                        path: 'audit',
                        view: 'account_profile',
                        requestBody: {
                            filters: {
                                values: {
                                    'user_management.audit.record->>id': { values: [entityId] },
                                    'user_management.audit.table_name': { values: ['account'] },
                                    'user_management.audit.op': { values: ['UPDATE'] },
                                },
                            },
                        },
                        processResponse: processAuditTrailRecords,
                    },
                },
            },
        ];
    } else if (entityType === UserPlatformManagementEntityTypes.User) {
        return [
            {
                screenerProps: {
                    cardName: 'Groups',
                    screenerRequestParams: {
                        path: 'group',
                        view: 'user_profile',
                        requestBody: {
                            filters: {
                                values: {
                                    'user_management.user_group.user_id': {
                                        values: [entityId],
                                    },
                                },
                            },
                        },
                    },
                },
                modals: [
                    {
                        props: {
                            title: 'Edit Groups',
                            submitButtonName: 'Save',
                            entityType: UserPlatformManagementEntityTypes.User_Group,
                            onOperationSuccessSnackMessage: 'Successfully created user {first_name} {last_name}',
                            onBeforeCreateSuccess: () => {},
                            operationType: OperationTypes.BULK_EDIT,
                        },
                    },
                ],
            },
            {
                screenerProps: {
                    cardName: 'Packages',
                    screenerRequestParams: {
                        path: 'group_package',
                        view: 'user_profile',
                        requestBody: {
                            filters: {
                                values: {
                                    'user_management.user_group.user_id': {
                                        values: [entityId],
                                    },
                                },
                            },
                        },
                    },
                },
            },
            {
                screenerProps: {
                    cardName: 'Notifications',
                    enableTopLeftActionPanel: true,
                    screenerRequestParams: {
                        path: 'user_alert_subscription',
                        view: 'user_profile',
                        requestBody: {
                            filters: {
                                values: {
                                    'user_management.user_alert_subscription.user_id': {
                                        values: [entityId],
                                    },
                                },
                            },
                        },
                    },
                    rowLevelFiltersConfig: {
                        requestParams: {
                            path: 'user_alert_subscription',
                            type: 'bulk_edit',
                        },
                        requestDefaultValue: {
                            id: '{row_id}',
                            values: '{action_value}',
                        },
                    },
                },
            },
            {
                screenerProps: {
                    cardName: 'User Audit Trail',
                    screenerRequestParams: {
                        path: 'audit',
                        view: 'user_group',
                        requestBody: {
                            filters: {
                                values: {
                                    $or: [
                                        {
                                            $and: [
                                                {
                                                    $or: [
                                                        {
                                                            'user_management.audit.record->>user_id': {
                                                                values: [entityId],
                                                            },
                                                        },
                                                        {
                                                            'user_management.audit.old_record->>user_id': {
                                                                values: [entityId],
                                                            },
                                                        },
                                                    ],
                                                },
                                                {
                                                    'user_management.audit.table_name': {
                                                        values: ['user_group'],
                                                    },
                                                },
                                                {
                                                    'user_management.audit.op': {
                                                        values: ['CREATE', 'UPDATE', 'DELETE'],
                                                    },
                                                },
                                            ],
                                        },
                                        {
                                            'user_management.audit.table_name': {
                                                values: ['user'],
                                            },
                                            'user_management.audit.op': {
                                                values: ['UPDATE'],
                                            },
                                            'user_management.audit.record->>id': {
                                                values: [entityId],
                                            },
                                        },
                                    ],
                                },
                            },
                        },
                        processResponse: processAuditTrailRecords,
                    },
                },
            },
        ];
    }
    throw new Error(`getEntityAssociatedItems exception. Pointed invalid entity type - '${entityType}'`);
}

export function getEntityFilterReqParams(
    entityType: UserPlatformManagementEntityTypes,
    operationType: OperationTypes,
): SearchByParams {
    if (entityType === UserPlatformManagementEntityTypes.User && operationType === OperationTypes.CREATE) {
        return {
            path: entityType,
            type: operationType,
            includeData: true,
            requestBody: {
                filters: {
                    values: {
                        'user_management.account.company_name': {
                            values: ['-1'],
                        },
                    },
                },
            },
        };
    }
    if (entityType === UserPlatformManagementEntityTypes.User_Group && operationType === OperationTypes.BULK_EDIT) {
        return {
            path: entityType,
            type: operationType,
            includeData: true,
            requestBody: {
                filters: {
                    values: {
                        'user_management.group.account_id': {
                            values: ['{filterPreRequestParamValues.user_group.group.account.id}'],
                        },
                    },
                },
            },
        };
    }
    if ([UserPlatformManagementEntityTypes.User, UserPlatformManagementEntityTypes.Account].includes(entityType)) {
        return { path: entityType, type: operationType, includeData: true };
    }

    throw new Error(`getEntityFilterReqParams exception. Pointed invalid entity type - '${entityType}'`);
}

export function getFilterPreRequestParams(
    entityType: UserPlatformManagementEntityTypes,
    operationType: OperationTypes,
    entityId?: number,
): SearchByParams {
    if (entityType === UserPlatformManagementEntityTypes.User_Group && operationType === OperationTypes.BULK_EDIT) {
        return {
            path: 'user',
            view: 'default',
            requestBody: {
                filters: {
                    values: {
                        'user_management.user.id': {
                            values: [entityId],
                        },
                    },
                },
            },
        };
    }
    return {};
}

export function modifyFormRequestBody(
    requestBody: any,
    entityType: UserPlatformManagementEntityTypes,
    operationType: OperationTypes,
    entityId?: number,
): any {
    if (entityType === UserPlatformManagementEntityTypes.User_Group && operationType == OperationTypes.BULK_EDIT) {
        if (requestBody[entityType]?.length > 0) {
            requestBody[entityType].forEach((req: any) => {
                req['user_id'] = req['user_id'] || entityId;
            });
        }
        return requestBody[entityType] || requestBody;
    }
    return requestBody;
}

export function getModifyDeleteRequestFunction(
    entityType: UserPlatformManagementEntityTypes,
    operationType: OperationTypes,
    screenerQueryResult: any,
) {
    return function (requestPath: string, deleteRequest: any) {
        if (entityType === UserPlatformManagementEntityTypes.User_Group && operationType === OperationTypes.BULK_EDIT) {
            const data = screenerQueryResult.data.results.data[0];
            const deleteEntity = data[UserPlatformManagementEntityTypes.User_Group]?.find(
                (p: any) => p.group.id === deleteRequest.key,
            );
            return {
                searchByParams: {
                    path: `${requestPath}/${deleteEntity.id}`,
                    config: {
                        enabled: true,
                    },
                    noErrorOnNoKeyValuePairs: true,
                },
                requestType: RequestTypes.DELETE,
                invalidate: false,
            };
        }
    };
}

export function getEntityScreenerReqParam(
    entityType: UserPlatformManagementEntityTypes,
    entityId: number,
): SearchByParams {
    if (entityType === UserPlatformManagementEntityTypes.Account) {
        return {
            path: 'account',
            view: 'account_profile',
            requestBody: {
                filters: {
                    values: {
                        'user_management.account.id': {
                            values: [entityId],
                        },
                    },
                },
            },
        };
    } else if (entityType === UserPlatformManagementEntityTypes.User) {
        return {
            path: 'user',
            view: 'user_profile',
            requestBody: {
                filters: {
                    values: {
                        'user_management.user.id': {
                            values: [entityId],
                        },
                    },
                },
            },
        };
    }
    throw new Error(`getEntityScreenerReqParam exception. Pointed invalid entity type - '${entityType}'`);
}

export function getRequestBody(requestBody: any, dirtyFields: any, getValues: any) {
    let body = {} as any;
    let getDirtyValue = (key: string): any => {
        let value = '-1';
        if (key in dirtyFields && getValues(key)) {
            value = getValues(key);
        }
        return value;
    };

    for (let key in requestBody) {
        let arg = requestBody[key].query_arg;
        let keyValues = getDirtyValue(key);
        body[arg] = { values: keyValues instanceof Array ? keyValues : [keyValues] };
    }

    return {
        filters: {
            values: {
                ...body,
            },
        },
    };
}
